import React from 'react';
import { useAuth } from './auth-context';

const UserContext = React.createContext();
// the UserProvider in user-context.js is basically:
const UserProvider = props => {
  const user = useAuth().data.user;
  let userContext;
  if (user) {
    userContext = {
      username: user.username,
      accessToken: user.signInUserSession.accessToken.payload,
      userGrps: user.signInUserSession.accessToken.payload['cognito:groups'].map(userGrp => userGrp.split('_')[1]),
    };
  }
  return <UserContext.Provider value={userContext} {...props} />;
};

const useUser = () => React.useContext(UserContext);

export { UserProvider, useUser };
