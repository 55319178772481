/**
 * Plant is like a template for user to create their own plant from one
 * This is stored in table 'plant'
 * @param {string} plantId
 * @param {string} plantname
 * @param {string} state
 * @param {string} country
 * @param {string} region_basis
 * @param {string} region
 * @param {string} default_units,
 * @param {string} default_currency,
 */
export function Plant(plantId, plantname, state, country, region_basis, region, default_units, default_currency) {
  this.plantId = plantId;
  this.plantname = plantname;
  this.state = state;
  this.country = country;
  this.region_basis = region_basis;
  this.region = region;
  this.default_units = default_units;
  this.default_currency = default_currency;
}
