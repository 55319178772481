/**
 *
 */
export function ProductCategory(region, AA, GA, protease, cellulase, yeast) {
  this.region = region;
  this.AA = AA;
  this.GA = GA;
  this.protease = protease;
  this.cellulase = cellulase;
  this.yeast = yeast;
}
