/**
 *
 */
export function BasisRegion(
  central_corn_belt,
  eastern_corn_belt,
  northeastern,
  southern,
  west_coast,
  western_corn_belt,
) {
  this.central_corn_belt = central_corn_belt;
  this.eastern_corn_belt = eastern_corn_belt;
  this.northeastern = northeastern;
  this.southern = southern;
  this.west_coast = west_coast;
  this.western_corn_belt = western_corn_belt;
}
