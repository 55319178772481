import React, { useEffect, useState } from 'react';
import { useUser } from './context/user-context';
import LoadingSpinner from './components/LoadingSpinner';

import { db, dbMapInit } from './db';

const AuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: 1, webpackChunkName: "authenticated" */ './authenticated-app'),
);
const UnAuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: 1, webpackChunkName: "unauthenticated" */ './unauthenticated-app'),
);

function App() {
  const user = useUser();
  const [dbLoading, setDbLoading] = useState(true);

  useEffect(() => {
    setDbLoading(true);
    if (!(user && user.accessToken)) {
      db.delete().then(() => setDbLoading(false));
    } else {
      db.open().then(() => {
        dbMapInit();
        setDbLoading(false);
      });
    }
  }, [user]);

  if (dbLoading) return <LoadingSpinner />;

  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      {user && user.accessToken ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
    </React.Suspense>
  );
}

export default App;
